import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'

import tradEn from './languages/en.json'
import tradDu from './languages/du.json'
import tradFr from './languages/fr.json'

const resources = {
  en: {
    global: tradEn
  },
  du: {
    global: tradDu
  },
  fr: {
    global: tradFr
  }
}

i18next.use(initReactI18next).init({
  debug: true,
  resources,
  lng: sessionStorage.getItem('curentLanguage') ? sessionStorage.getItem('curentLanguage') : 'fr'
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>{' '}
  </React.StrictMode>
);