import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import { useTranslation } from 'react-i18next'
import Slider from "react-slick";

import telegram from './assets/images/telegram-svgrepo-com.svg'
import whatsapp from './assets/images/whatsapp-128-svgrepo-com.svg'
import facebook from './assets/images/facebook-svgrepo-com .svg'
import instagram from './assets/images/instagram-svgrepo-com.svg'
import discord from './assets/images/discord-svgrepo-com.svg'
import banner from './assets/images/photo_2024-05-27_14-35-33.jpg'
import pourquoi from './assets/images/car5.png'
import phone from './assets/images/ok-circle-filled-svgrepo-com.svg'
import bubble from './assets/images/bubble-speech-phone-message-svgrepo-com.svg'
import speed from './assets/images/speed-svgrepo-com.svg'
import mercedes_b_classe_w245_b200 from './assets/images/mercedes_b_classe_w245_b200.png'
import jeep_grand_cherokee from './assets/images/jeep_grand_cherokee.png'
import toyota_v8 from './assets/images/toyoto_v8.png'
import v1 from './assets/images/car2.png'
import v2 from './assets/images/car3.png'
import v3 from './assets/images/car5.png'
import v4 from './assets/images/car1.png'
import consultation from './assets/images/forum-message-svgrepo-com.svg'
import accompagnement from './assets/images/driver-svgrepo-com.svg'
import location from './assets/images/car-rental-svgrepo-com.svg'
import assurance from './assets/images/quality-premium-certificate-svgrepo-com.svg'
import assistance from './assets/images/settings-screwdriver-svgrepo-com.svg'
import fille from './assets/images/photo_card_gabriel.jpg'
import callcenter from './assets/images/callcenter.png'
import noprofil from './assets/images/no_profil.png'
import star from './assets/images/star.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from 'react-scroll';

import emailjs from '@emailjs/browser';

function App() {
  const {t} = useTranslation('global')

  var settings1 = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    speed: 500,
  };
  var settings2 = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };
  var settings3 = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
  };
  

  const sendMail = () => {
    const btn = document.getElementById('button');
    document.getElementById('form')
  .addEventListener('submit', function(event) {
    event.preventDefault();

    btn.value = t('email.envoie');

    const serviceID = 'default_service';
    const templateID = 'template_y03hkbr';

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, this, process.env.REACT_APP_PUBLIC_KEY)
      .then(() => {
        btn.value = t('email.envoyer');
        alert('Sent!');

      }, (err) => {
        btn.value = t('email.envoyer');
        alert(JSON.stringify(err));
      });
  });
}
  
  const sendEmail =(e) => {
    alert('it work')
    e.preventDefault();
    emailjs.sendForm('service_2jcywsm', 'template_y03hkbr', e.target, 'rFOsPOx3kIqdH1d3F')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text)
    });
  }
  return (
    <div className="App">
      <Header></Header>
      <div className='suiteHeader'>
        Tel: <span><a href='tel:+237 696 007 887'>+237 696007887</a></span>
      </div>
      <div className='banner'>
        <img src={banner} alt='Banner' className='bannerImg'/>
        <div className='bannerInfo'>
          <div>
            {t('banner.text1')}
          </div>
          <div>
            {t('banner.text2')}
          </div>
          <div>
          {t('banner.text3')}
          </div>
        </div>
        <div className='socialMedia'>
          <img src={telegram} alt='Telegram'/>
          <img src={whatsapp} alt='Whatsapp'/>
          <img src={facebook} alt='Facebook'/>
          <img src={instagram} alt='Instagram'/>
          <img src={discord} alt='Discord'/>
        </div>
      </div>
      <div className='slogan'>
        <div>{t('slogan')}</div>
      </div>
      <div className='video'>
        <div>
        <iframe id='CarShiper' src= {t('youtube_presentation_link')}  width='100%' height='100%' allowfullscreen webkitallowfullscreen mozallowfullscreen allow='autoplay *; fullscreen *; encrypted-media *' sandbox='allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation' frameborder='0' title='CarShiper'>

        </iframe>
        </div>
      </div>
      <div className='pourquoi'>
        <h1>{t('pourquoi.pourquoiNous')}</h1>
        <p>{t('pourquoi.pourquoiNousInfo')}</p>
        <div>
          <ol>
            <li>
              <h1>{t('pourquoi.assistance')}</h1>
              <div>{t('pourquoi.assistanceInfo')}</div>
            </li>
            <li>
              <h1>{t('pourquoi.price')}</h1>
              <div>{t('pourquoi.priceInfo')}</div>
            </li>
            <li>
              <h1>{t('pourquoi.location')}</h1>
              <div>{t('pourquoi.locationInfo')}</div>
            </li>
            <li>
              <h1>{t('pourquoi.experience')}</h1>
              <div>{t('pourquoi.experienceInfo')}</div>
            </li>
          </ol>
          <img src={pourquoi} alt='.'/>
        </div>
        <div className='reserver'><button><a href='tel:+237 696 007 887'>{t('pourquoi.reserver')}</a></button></div>
      </div>
      <div className='comment'>
        <h1>{t('comment.commentMarche')}</h1>
        <div>
          <div>
            <img src={phone} alt='ico'/>
            <h2>{t('comment.c1')}</h2>
            <p>{t('comment.c1Info')}</p>
          </div>
          <div>
            <img src={bubble} alt='ico'/>
            <h2>{t('comment.c2')}</h2>
            <p>{t('comment.c2Info')}</p>
          </div>
          <div>
            <img src={speed} alt='ico'/>
            <h2>{t('comment.c3')}</h2>
            <p>{t('comment.c3Info')}</p>
          </div>
        </div>
      </div>
      <div className='about'>
        <h1>{t('about.about')}</h1>
        <div className='c1'>
          <img src={v1} alt='.'/>
          <div>
          <p>{t('about.c1')}</p>
          </div>
        </div>
        <div className='c2'>
          <img src={v2} alt='.'/>
          <div>
          <p>{t('about.c2')}</p>
          </div>
        </div>
        <div className='c3'>
          <img src={v3} alt='.'/>
          <div>
          <p>{t('about.c3')}</p>
          </div>
        </div>
        <div className='c4'>
          <img src={v4} alt='.'/>
          <div>
          <p>{t('about.c4')}</p>
          </div>
        </div>
      </div>
      <div className='services'>
        <h1>{t('services.services')}</h1>
        <div>
          <img src={consultation} alt='.'/>
          <div>
            <h2>{t('services.c1')}</h2>
            <div>
            {t('services.c1Infos')}
            </div>
          </div>
        </div>
        <div>
          <img src={accompagnement} alt='.'/>
          <div>
            <h2>{t('services.c2')}</h2>
            <div>
            {t('services.c2Infos')}
            </div>
          </div>
        </div>
        <div>
          <img src={location} alt='.'/>
          <div>
            <h2>{t('services.c3')}</h2>
            <div>
            {t('services.c2Infos')}
            </div>
          </div>
        </div>
        <div>
          <img src={assurance} alt='.'/>
          <div>
            <h2>{t('services.c4')}</h2>
            <div>
            {t('services.c4Infos')}
            </div>
          </div>
        </div>
        <div>
          <img src={assistance} alt='.'/>
          <div>
            <h2>{t('services.c5')}</h2>
            <div>
            {t('services.c5Infos')}
            </div>
          </div>
        </div>
      </div>
      <div className='preoccupations'>
        <img src={callcenter} alt=''/>
        <div>
          <h1>{t('preoccupations.preoccupations')}</h1>
          <p>{t('preoccupations.message')}</p>
          <div>
          {/*<div>
            <textarea className='subject'>{t('preoccupations.sujet')}</textarea>
            </div>
            <textarea className='message'></textarea>*/}
            

            <form id="form">
              <div class="field">
                <label for="email_from">{t('email.your_email')} : </label>
                <input type="text" name="email_from" id="email_from"/>
              </div>
              <div class="field">
                <label for="message">{t('email.message')} : </label>
                <textarea type="text" name="message" id="message"/>
              </div>

              <input type="submit" id="button" value= {t('email.envoyer')} onClick={sendMail}/>
            </form>
          </div>
        </div>

      </div>
      <div className='cars'>
        <div>
          <Slider {...settings1}>
            <div className='carte'>
              <img src={mercedes_b_classe_w245_b200} alt='Mercedes'/>
              <div  className='info1'>
              <div  className='model'>
                  Mercedes B-classe w245
                </div>
                <div  className='tarif'>
                  35.000F/{t('car.jour')}
                </div>
                <div  className='chauffeur'>
                {t('carte.chauffeur')}
                </div>
                <div  className='minimum'>
                  min 2 {t('car.jours')}
                </div>
              </div>
              <div  className='info2'>
              <div  className='annee'>
                  2009
                </div>
                <hr/>
                <div  className='moteur'>
                {t('car.manuelle')}
                </div>
                <hr/>
                <div  className='conso'>
                  {t('car.gasoil')}
                </div>
                <hr/>
                <div  className='caution'>
                  205.000KM
                </div>
              </div>
            </div>
            <div className='carte'>
              <img src={jeep_grand_cherokee} alt='Mercedes'/>
              <div  className='info1'>
              <div  className='model'>
                  Jeep grand cherokee
                </div>
                <div  className='tarif'>
                  45.000F/{t('car.jour')}
                </div>
                <div  className='chauffeur'>
                  {t('carte.chauffeur')}
                </div>
                <div  className='minimum'>
                  min 2 {t('car.jours')}
                </div>
              </div>
              <div  className='info2'>
              <div  className='annee'>
                  2006
                </div>
                <hr/>
                <div  className='moteur'>
                {t('car.automatique')}
                </div>
                <hr/>
                <div  className='conso'>
                {t('car.gasoil')}
                </div>
                <hr/>
                <div  className='caution'>
                  205.000KM
                </div>
              </div>
            </div>
            <div className='carte'>
              <img src={toyota_v8} alt='Mercedes'/>
              <div  className='info1'>
              <div  className='model'>
                  Toyota V8
                </div>
                <div  className='tarif'>
                  45.000F/{t('car.jour')}
                </div>
                <div  className='chauffeur'>
                {t('carte.chauffeur')}
                </div>
                <div  className='minimum'>
                  min 2 {t('car.jours')}
                </div>
              </div>
              <div  className='info2'>
              <div  className='annee'>
                  2002
                </div>
                <hr/>
                <div  className='moteur'>
                {t('car.automatique')}
                </div>
                <hr/>
                <div  className='conso'>
                {t('car.essence')}
                </div>
                <hr/>
                <div  className='caution'>
                  205.000KM
                </div>
              </div>
            </div>
            <div className='carte'>
              <img src={v4} alt='Mercedes'/>
              <div  className='info1'>
              <div  className='model'>
                  Mercedes B-classe w245
                </div>
                <div  className='tarif'>
                  45.000F/{t('car.jour')}
                </div>
                <div  className='chauffeur'>
                {t('carte.chauffeur')}
                </div>
                <div  className='minimum'>
                  min 2 {t('car.jours')}
                </div>
              </div>
              <div  className='info2'>
              <div  className='annee'>
                  2009
                </div>
                <hr/>
                <div  className='moteur'>
                {t('car.manuelle')}
                </div>
                <hr/>
                <div  className='conso'>
                {t('car.gasoil')}
                </div>
                <hr/>
                <div  className='caution'>
                  205.000KM
                </div>
              </div>
            </div>
          </Slider>
      
        </div>
      </div>
      <div className='drivers'>
        <div>
            <Slider {...settings2}>

            <div className='carte'>
                <div>
                <img src={fille} alt='Mercedes'/>
                <div  className='info'>
                  <div>
                  <div  className='nom'>
                  GABRIEL WANDJI
                  </div>
                  <div  className='tardescripyionif'>
                  {t('carte.commercial_director')}
                  </div>
                  </div>
                  <div  className='chauffeur'>
                  {t('carte.permi_type')} B
                  </div>
                  <div>
                  <div  className='tel'>
                  +237 6 96 00 78 87
                  </div>
                  <div  className='mail'>
                  info@carships.org
                  </div>
                  </div>
                </div>
                </div>
              </div>
            
            <div className='carte'>
                <div>
                <img src={noprofil} alt='Mercedes'/>
                <div  className='info'>
                  <div>
                  <div  className='nom'>
                  IVAN DJAKOU
                  </div>
                  <div  className='tardescripyionif'>
                  {t('carte.informatic_responsable')}
                  </div>
                  </div>
                  <div  className='chauffeur'>
                  {t('carte.permi_type')} A,B,C
                  </div>
                  <div>
                  <div  className='tel'>
                  +237 6 91 64 00 99 / 6 20 41 29 48 
                  </div>
                  <div  className='mail'>
                  info@carships.org
                  </div>
                  </div>
                </div>
                </div>
              </div>
              <div className='carte'>
                <div>
                <img src={noprofil} alt='Mercedes'/>
                <div  className='info'>
                  <div>
                  <div  className='nom'>
                  LAWRENCE
                  </div>
                  <div  className='tardescripyionif'>
                  {t('carte.chauffeur')}
                  </div>
                  </div>
                  <div  className='chauffeur'>
                  {t('carte.permi_type')} B
                  </div>
                  <div>
                  <div  className='tel'>
                  +237 6 70 48 37 09 
                  </div>
                  <div  className='mail'>
                  info@carships.org
                  </div>
                  </div>
                </div>
                </div>
              </div>
              <div className='carte'>
                <div>
                <img src={noprofil} alt='Mercedes'/>
                <div  className='info'>
                  <div>
                  <div  className='nom'>
                  RUCLIN NANA
                  </div>
                  <div  className='tardescripyionif'>
                  {t('carte.promoteur')}
                  </div>
                  </div>
                  <div  className='chauffeur'>
                  {t('carte.permi_type')} B
                  </div>
                  <div>
                  <div  className='tel'>
                  +237 6 95 66 35 75 / 6 70 82 47 72
                  </div>
                  <div  className='mail'>
                  info@carships.org
                  </div>
                  </div>
                </div>
                </div>
              </div>
            </Slider>
        
          </div>
      </div>
      <div className='appreciation'>
        <Slider {...settings3}>
          <div className='carte'>
            <div>
              <div className='stars'>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
              </div>
              <div>
              Carships provided an exceptional rental experience. The cars are in excellent condition, and the customer service is impeccable.               </div>
            </div>
          </div>
          <div className='carte'>
            <div>
              <div className='stars'>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
              </div>
              <div>
              I appreciated the flexibility of the rental options and the quick response from the assistance team. I highly recommend Carships.
              </div>
            </div>
          </div>
          <div className='carte'>
            <div>
              <div className='stars'>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
              </div>
              <div>
              Très simple sans prise de tête, juste un appel et je suis passée recupérer le véhicule
              </div>
            </div>
          </div>
          <div className='carte'>
            <div>
              <div className='stars'>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
                <div className='star'><img src={star} alt=' '/></div>
              </div>
              <div>
              Merci!
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className='map'>

      </div>
      <Footer></Footer>
    </div>
  );
  

}

export default App;
