import React, { useState, } from 'react'
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import logo from '../assets/images/logo.svg'
import '../assets/css/header.css'
import { useTranslation } from 'react-i18next'

function Header() {
    const { t, i18n } = useTranslation('global')
    const curentLanguage = sessionStorage.getItem('curentLanguage') ? sessionStorage.getItem('curentLanguage') : 'fr'
    const [ln, setLn] = useState(<div><strong>{curentLanguage}</strong></div>)
    const [open, setOpen] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const handleisOpen = () => {
        setIsOpen(!isOpen)
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleChangeLang = (lang, e) => {
        sessionStorage.setItem('curentLanguage', lang)
        i18n.changeLanguage(lang)
        setOpen(false)
        setIsOpen(false)
        if (lang === 'en') {
            setLn(<div>
                <strong>en</strong>
            </div>)
        } else if (lang === 'du') {
            setLn(<div>
                <strong>de</strong>
            </div>)
        } else if (lang === 'fr') {
            setLn(<div>
                <strong>fr</strong>
            </div>)
        }
    }
    return (
        <>
            <div className={isOpen ? 'open header' : 'header'}>
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className='nav'>
                    <div className="links">
                        <Link activeClass="active" className='test1' to="banner" spy={true} smooth={true} duration={500} offset={-60}>{t('general.home')}</Link >
                        <Link activeClass="active" className='test2' to="about" spy={true} smooth={true} duration={500} offset={-60}>{t('general.about')}</Link >
                        <Link activeClass="active" className='test3' to="services" spy={true} smooth={true} duration={500} offset={-60}>{t('general.services')}</Link >
                        <Link activeClass="active" className='test4' to="cars" spy={true} smooth={true} duration={500} offset={-60}>{t('general.cars')}</Link >
                        <Link activeClass="active" className='test5' to="drivers" spy={true} smooth={true} duration={500} offset={-60}>{t('general.contact')}</Link >
                    </div>
                    <div className="languages">
                        <button onClick={handleOpen}>
                            {React.cloneElement(ln)}
                        </button>
                        {
                            open
                                ? <ol>
                                    <li>
                                        <button onClick={(e) => handleChangeLang('fr', e)}> <span>Français</span> fr</button>
                                    </li>
                                    <li>
                                        <button onClick={(e) => handleChangeLang('en', e)}> <span>English</span> en</button>
                                    </li>
                                    <li>
                                        <button onClick={(e) => handleChangeLang('du', e)}> <span>Deutsh</span> de</button>
                                    </li>
                                </ol>
                                : null
                        }
                    </div>
                </div>
                <div class="menuBar" onClick={handleisOpen}>
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </div>
        </>
    )
}
export default Header