import '../assets/css/footer.css'
import logo from '../assets/images/logo_complet.svg'
import location from '../assets/images/location-svgrepo-com.svg'
import phone from '../assets/images/phone-calling-rounded-svgrepo-com.svg'
import mail from '../assets/images/mail-forward-svgrepo-com.svg'
import telegram from '../assets/images/telegram-svgrepo-com.svg'
import whatsapp from '../assets/images/whatsapp-128-svgrepo-com.svg'
import facebook from '../assets/images/facebook-svgrepo-com .svg'
import instagram from '../assets/images/instagram-svgrepo-com.svg'
import discord from '../assets/images/discord-svgrepo-com.svg'
import { useTranslation } from 'react-i18next'

function Footer() {
    const { t } = useTranslation('global')
    return (
        <footer>
            <div className="logoTop">
                <img src={logo} alt="logo" />
            </div>
            <div className='video'>
        <div>
        <iframe id='CarShiper' src= 'https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=simpla%20%20the%20market%20place+(CarShip)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'  width='100%' height='100%' allowfullscreen webkitallowfullscreen mozallowfullscreen allow='autoplay *; fullscreen *; encrypted-media *' sandbox='allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation' frameborder='0' title='CarShiper'>
        </iframe>
        </div>
      </div>
            <div className="division">
                <div className="logo">
                    <img src={logo} alt="logo" />
                    <br />
                    <div className='socialMedia'>
                        <img src={telegram} alt='Telegram' />
                        <img src={whatsapp} alt='Whatsapp' />
                        <a  href='https://www.facebook.com/profile.php?id=61561341051921&mibextid=kFxxJD' target= 'blank'><img src={facebook} alt='Facebook' /></a>
                        <img src={instagram} alt='Instagram' />
                        <img src={discord} alt='Discord' />
                    </div>
                </div>
                <div className='link'>
                    <h1>
                        {t('general.lienRapide')}
                    </h1>
                    <ol>
                        <li>
                            {t('general.home')}
                        </li>
                        <li>
                            {t('general.about')}
                        </li>
                        <li>
                            {t('general.services')}
                        </li>
                        <li>
                            {t('general.cars')}
                        </li>
                    </ol>
                </div>
                <div>
                    <h1>{t('general.contact')}</h1>
                    <div className='contact'>
                        <div className="localisation">
                            <img src={location} alt="Location" />
                            Douala, Cameroun, Dernier Poteau <br /> (Boulevard des nations unies)
                        </div>
                        <div className="mail">
                            <img src={mail} alt="mail" />
                            <a href="mailto:info@carships.org">info@carships.org</a>
                        </div>
                        <div className="phone">
                            <img src={phone} alt="phone" />
                            <a href="tel:+237 670 824 772"> +237 670 824 772</a>
                            <br />
                            <a href="tel:+237 696 007 887"> +237 696 007 887</a>
                        </div>
                    </div>
                </div>

            </div>
            <div className="copyright">
                (c) 2024 All rights reserved.
            </div>
        </footer>
    )
}
export default Footer